import Styled from 'styled-components';

export const Container = Styled.div`
  display: block;
  position: relative;
  width: 100%;
  height: 100vh;
  @media (max-width: 700px) {
    padding-top: 40px;
  }
`;

export const FiveGContainer = Styled(Container)`
  padding-top: 100px;
  height: auto;
`;

export const TopContainer = Styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  overflow: auto;
  height: 100%;
  width: 100%;

  @media (max-width: 700px) {
    flex-direction: column;
  }
`;

export const TopBar = Styled.div`
  display: block;
  width: 100%;
`;

export const IconLogo = Styled.img`
  display: block;
  width: 300px;
  padding-top: 25px;
`;

export const Footer = Styled.footer`
  display: block;
  margin: 0 auto;
  width: 100%;
  font-family: 'Helvetica Neue Roman', sans-serif;
  font-size: 12px;
  color: #BBBABA;
  text-align: center;
`;

export const MottoContainer = Styled.div`
  display: flex;
  justify-content: left;
  align-items: center;
  margin: 0 auto;
  width: 100%;
  height: calc(100vh - 60px);
  padding-top: 60px;
  z-index: 0;
`;

export const Motto = Styled.h1`
  display: block;
  position: relative;
  font-family: 'Helvetica Neue Roman', sans-serif;
  font-size: 36px;
  color: #fff;
  text-align: left;
  margin-top: 20px;
  padding: 0;
`;

export const Submotto = Styled(Motto)`
  font-family: 'Helvetica Neue Light', sans-serif;
  font-size: 24px;
  margin-top: -22px;
  line-height: 30px;
`;

export const Title = Styled.h1`
  display: block;
  font-family: 'Helvetica Neue Roman', sans-serif;
  font-size: 26px;
  color: #494956;
  text-align: center;
  width: 50%;
  margin: 0 auto;
`;

export const Subtitle = Styled(Title)`
  font-size: 16px;
  padding-top: 35px;
`;

export const CardsContainer = Styled.div`
  display: flex;
  height: 400px;
  width: 100%;
  position: relative;
  justify-items: center;
  align-items: center;
  justify-content: space-between;
  padding-top: 120px;
`;

export const Card = Styled.div`
  display: block;
  width: 23%;
  height: 400px;
  background-color: #eee;
`;

export const ProductsContainer = Styled.div`
  display: block;
  width: 100%;
  overflow: auto;
  margin-bottom: 40px;  
  padding-top: 100px;
  @media (max-width: 700px) {
    padding-top: 50px;
  }
`;

export const FiveGProductsContainer = Styled.div`
  display: block;
  width: 100%;
  overflow: auto;
  margin-bottom: 40px;  
  @media (max-width: 700px) {
    padding-top: 50px;
  }
`;

export const ProductPhotoContainer = Styled.div`
  display: block;
  position: relative;
  width: 38%;
  height: 700px;
  float: left;
  overflow: hidden;
  @media (max-width: 700px) {
    width: 85%;
  }
`;

export const ProductTextContainer = Styled.div`
  display: block;
  width: 58%;
  float: left;
  @media (max-width: 700px) {
    width: 85%;
  }
`;

export const ProductTitle = Styled(Title)`
  width: auto;
  text-align: left;
  color: #494956;
  font-family: 'Helvetica Neue Roman', sans-serif;
  font-size: 22px;
  @media (max-width: 700px) {
    letter-spacing: -0.5px;
  }
`;

export const ProductSubtitle = Styled(ProductTitle)`
  font-size: 14px;
  padding-top: 12px;
  font-family: 'Helvetica Neue Light', sans-serif;
  color: #BBB;
  @media (max-width: 700px) {
    letter-spacing: -0.5px;
  }
`;

export const ProductDescription = Styled(Subtitle)`
  font-family: 'Helvetica Neue Light', sans-serif;
  font-size: 15px;
  width: auto;
  text-align: left;
  line-height: 19px;
  padding-top: 15px;
  letter-spacing: .5px;
  color: #494956;

  @media (max-width: 700px) {
    letter-spacing: -0.5px;
  }
`;

export const Copyright = Styled(Subtitle)`
  padding-bottom: 15px;
  font-size: 13px;
  color: #444;
  white-space: nowrap;
  text-align: center;
`;

export const CardLink = Styled.a`
  display: inline-block;
  position: relative;
  width: 100%;
  height: 100%;
`;

export const CardText = Styled(Subtitle)`
  color: #fff;
  position: absolute;
  z-index: 100;
  width: auto;
  top: 50%;
  font-size: 17px;
  left: ${props => (props.position === 'left') ?
    '10%' : 'auto'
  };
  right: ${props => (props.position === 'left') ?
    'auto' : '5%'
  };
  transform: ${props => (props.position === 'left') ?
    'translate(-10%, -50%)' : 'translate(-5%, -50%)'
  };
  text-align: center;
`;

export const TitleLink = Styled.a`
  color: #494956;

  :hover {
    color: #888;
    transition: color .3s;
  }
`;

export const GrayLine = Styled.div`
  position: relative;
  float: left;
  height: 100%;
  width: 1px;
  background-color: #CCCCCC;
  margin-left: 2.5%;
  margin-right: 2.5%;
`;

export const TitleContainer = Styled.div`
  display: block;
  width: 100%;
  float: left;
  padding-top: 25px;
`;

export const SliderContainer = Styled.div`
  display: flex;
  align-items: top;
  justify-content: left;
  float: left;
  width: 100%;
  height: 100%;
  padding-top: 20px;
  @media (max-width: 700px) {
    padding-top: 20px;
  }
`;

export const ProductSpecs = Styled.div`

`;

export const SpecTitle = Styled(Title)`
  text-align: left;
  font-size: 12px;
  line-height: 16px;
  float: left;
  width: 100%;
  font-family: 'Helvetica Neue Light', sans-serif;
  color: #494956;
  letter-spacing: 0.5px;
  @media (max-width: 700px) {
    letter-spacing: -0.5px;
  }
`;

export const Spec = Styled.li`
  text-align: right;
  float: left;
  width: 100%;
  clear: right;
  text-decoration: none;
  list-style: none;
  font-size: 12px;
  margin: 0;
  padding: 0;
  font-family: 'Helvetica Neue Light', sans-serif;
  color: #494956;
  letter-spacing: 0.5px;

  @media (max-width: 700px) {
    letter-spacing: -0.5px;
  }
`;

export const SpecContainer = Styled.ul`
  display: flex;
  justify-content: space-between;
  align-items: top;
  float: left;
  margin: 0;
  padding: 15px 0;
  width: 100%;
`;

export const ProductTitleSmall = Styled(ProductTitle)`
  font-size: 14px;
  padding-bottom: 8px;
  text-align: left;
  width: 100%;
  font-family: 'Helvetica Neue Light', sans-serif;
`;

export const LeftSpec = Styled.div`
  float: left;
  width: 30%;
`;

export const RightSpec = Styled.div`
  float: right;
  width: 80%;
  text-align: right;
`;

export const SpecsLink = Styled(ProductDescription)`
  padding-top: 40px;
`;

export const SpecsArrow = Styled.img`
  display: inline-block;
  width: 20px;
  height: 20px;
  padding: 0;
  margin: 0 0 -5px 0;
`;

export const ProductBar = Styled.div`
  display: block;
  width: 100%;
  height: 18px;
  margin-top: 15px;
  border-bottom: 1px solid #CCCCCC;
`;

export const BarTitle = Styled(Subtitle)`
  font-size: 12px;
  width: auto;
  float: left;
  padding: 0;
  font-family: 'Helvetica Neue Light', sans-serif;
  letter-spacing: .5px;
`;

export const SecondBarTitle = Styled(BarTitle)`
  color: #CCCCCC;
  padding-left: 10px;
`;

export const BarLink = Styled.a`
  display: inline-block;
  text-decoration: none;
  :hover {
    color: #000;
    transition: color .3s;
  }

  &:after {
    content: "";
    position: absolute;
    z-index: -1;
    right: 21px;
    width: 0;
    bottom: -4px;
    background: #000;
    height: 2px;
    transition-property: width;
    transition-duration: 0.3s;
    transition-timing-function: ease-out;
  }

  &:hover:after,
  &:focus:after,
  &:active:after {
    left: 21px;
    right: auto;
    background: #000;
    width: ${props => props.width + 'px'};
  }

  &#active:after {
    left: 21px;
    right: auto;
    background: #000;
    width: ${props => props.width + 'px'};
  }

  &#active {
    color: #000;
  }
`;

export const LeftColumn = Styled.div`
  display: block;
  float: left;
  width: 50%;
  border-right: 1px solid #CCCCCC;
  padding-right: 3%;
`;

export const RightColumn = Styled(LeftColumn)`
  border-right: none;
  padding-left: 3%;
  padding-right: 0;
`;

export const ThreeDTextContainer = Styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  bottom: 0;
  right: 0;
  width: 50px;
  height: 40px;
  background-color: rgba(256,256,256,.35);
  cursor: grab;
`;

export const DarkThreeDTextContainer = Styled(ThreeDTextContainer)`
  background-color: #eee !important;
  width: 40px !important;
  bottom: none;
`;

export const ThreeDText = Styled(Subtitle)`
  padding: 0;
  margin: 0 0 0 2px;
  font-size: 18px;
  color: #fff;
`;

export const ArrowImageIcon = Styled.img`
  display: block;
  position: absolute;
  width: 12px;
  height: 12px;
  left: 6px;
  top: 6px;
`;

export const CancelImageIcon = Styled.img`
  display: block;
  position: absolute;
  width: 16px;
  height: 16px;
  left: 12px;
  top: 12px;
`;

export const CloseImageIcon = Styled.img`
  display: block;
  position: absolute;
  width: 12px;
  height: 12px;
  right: 5px;
  top: 23px;
  transform: rotate(180deg);
`;

export const BoxContainer = Styled.div`
  display: block;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-color: #fff;
`;