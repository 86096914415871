import React from "react";
import { StaticQuery, graphql } from 'gatsby';
import Img from "gatsby-image";
import Styled from "styled-components";

const GalleryImg = Styled(Img)`
  height: 100% !important;
  position: relative !important;
  width: auto !important;
  opacity: 1;
`;

const ProductImage = props => (
  <StaticQuery
    query={graphql`
      query {
        images: allFile {
          edges {
            node {
              relativePath
              name
              childImageSharp {
                fluid(maxHeight: 600, quality: 100) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    `}

    render={data => {
      const image = data.images.edges.find(n => {
        return n.node.relativePath.includes(props.filename);
      });

      if (!image) {
        return null;
      }

      return (
        
        <GalleryImg alt={props.alt} fluid={image.node.childImageSharp.fluid} loading="eager" imgStyle={{objectFit: 'cover'}} />
      );
    }}
  />
);

export default ProductImage;
