import React from "react";
import { Link } from "gatsby";
import { StaticQuery, graphql } from 'gatsby'
import { Helmet } from "react-helmet";

import Layout from "../components/layout.tsx";
import Image from "../components/image.tsx";
import ProductImage from "../components/productImage.tsx";
import ArrowIcon from "../images/arrow-icon.svg";
import CancelIcon from "../images/close.svg";
import { ThreeDimensionalModel } from "../components/threeDimensionalModel.tsx";

import { 
  Container, 
  FiveGContainer,
  Motto, 
  Submotto, 
  MottoContainer,
  Title, 
  Subtitle, 
  CardsContainer, 
  Card, 
  FiveGProductsContainer,
  ProductPhotoContainer,
  TopContainer,
  ProductTextContainer,
  ProductTitle,
  ProductDescription,
  Copyright,
  CardLink,
  CardText,
  TitleLink,
  GrayLine,
  SpecTitle,
  Spec,
  TitleContainer,
  SliderContainer,
  SpecContainer,
  ProductSubtitle,
  ProductTitleSmall,
  LeftSpec,
  RightSpec,
  SpecsLink,
  SpecsArrow,
  ProductBar,
  BarTitle,
  SecondBarTitle,
  BarLink,
  LeftColumn,
  RightColumn,
  DarkThreeDTextContainer,
  ThreeDTextContainer,
  ThreeDText,
  ArrowImageIcon,
  CancelImageIcon,
  CloseImageIcon,
  BoxContainer
} from "../styles/ProductStyles.ts";

const FiveGProductsPage = () => {
  const [show3DModel, setShow3DModel] = React.useState([false, false, false]);

  const handleToggle = (index) => {
    let array = show3DModel;
    array[index] = !array[index];
    setShow3DModel([...array]);
  }
  
  return (
    <StaticQuery 
      query={graphql`
        query {
          site {
            siteMetadata { 
              fiveGProducts {
                title
                subtitle
                shortTitle
                description
                imageUrl
                specs {
                  specTitle
                  spec
                }
              }
            }
          }
        }
      `}
      render={data => (
        <div>
          <Layout>
            <Helmet>
              <title>5G Products - Abside Networks</title>
            </Helmet>
            <FiveGContainer>
            {data.site.siteMetadata.fiveGProducts.map(function(product, index) {
              return (
                <FiveGProductsContainer key={index} id={product.shortTitle}>
                  <TopContainer>
                    <ProductPhotoContainer>
                      <ProductImage filename={product.imageUrl} />
                    </ProductPhotoContainer>
                    <ProductTextContainer>
                      <TitleContainer>
                        <ProductTitle>{product.title}</ProductTitle>
                        <ProductSubtitle>{product.subtitle}</ProductSubtitle>
                        <ProductDescription>{product.description}</ProductDescription>
                      </TitleContainer>
                      <SliderContainer>
                        <LeftColumn>
                          {product.specs.map((spec, index) => {
                            if (index < (product.specs.length / 2)) {
                              return (
                              <div key={spec.specTitle}>
                                <SpecContainer>
                                  <LeftSpec>
                                    <SpecTitle>{spec.specTitle}</SpecTitle>
                                  </LeftSpec>
                                  <RightSpec>
                                    {spec.spec.map(function(individualSpec, index) {
                                      return (
                                          <Spec key={index}>{individualSpec}</Spec>
                                      )
                                    })}
                                  </RightSpec>
                                </SpecContainer>
                              </div>
                              )
                            }
                          })}
                          </LeftColumn>
                          <RightColumn>
                            {product.specs.map((spec, index) => {
                              if (index >= (product.specs.length / 2)) {
                                return (
                                <div key={index}>
                                  <SpecContainer>
                                    <LeftSpec>
                                      <SpecTitle>{spec.specTitle}</SpecTitle>
                                    </LeftSpec>
                                    <RightSpec>
                                      {spec.spec.map(function(individualSpec, index) {
                                        return (
                                            <Spec key={index}>{individualSpec}</Spec>
                                        )
                                      })}
                                    </RightSpec>
                                  </SpecContainer>
                                </div>
                                )
                              }
                            })}
                          </RightColumn>
                      </SliderContainer>
                    </ProductTextContainer>
                  </TopContainer>
                </FiveGProductsContainer>
              )
            })}
              <Copyright>Copyright © {new Date().getFullYear()} Abside Networks, Inc.</Copyright>
            </FiveGContainer>
          </Layout>
        </div>
      )}
    />
    )
}

export default FiveGProductsPage;
